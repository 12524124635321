
import { defineComponent } from "vue";
//import { Form } from "vee-validate";
//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "Attendance-summary",
  components: {
    Datatable,
  },
  data() {
    return {
      start_date: "",
      end_date: "",
      tableData: [],
      componentKey: 0,
      showTable: false,
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "Entity Name",
          key: "entity_name",
          sortable: true,
        },
        {
          name: "Institute Name",
          key: "institute_name",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "Batch",
          key: "batch",
          sortable: true,
        },
        {
          name: "Attendance Percentage",
          key: "attendance_percentage",
          sortable: true,
        },
      ],
      loading: false,
    };
  },
  async created() {},
  methods: {
    async getData() {
      this.loading=true;
      ApiService.get(
        "enrollment/attendance_summary?start_date=" +
          this.start_date +
          "&end_date=" +
          this.end_date
      )
        .then((response) => {
          this.loading=false;
          this.tableData = response.data.data;
          this.showTable = true;
          console.log(response);
        })
        .catch((response) => {
          this.loading=false;
          console.log(response);
        });
    },
  },
  setup() {},
});
